

/*------------------------------------------
          Contact Start Here
-------------------------------------------*/

.login-main-dark{
    background-image: url(/assets/img/login-img.png);
    background-size: cover;
    background-repeat: no-repeat;
    .container-fluid{
        .log-in{
            padding: 32px 0 24px 0;
            .nav-bar{
                display: flex;
                align-items: center;
                justify-content: space-between;
                .logo-otr{
                    .logo-inr{
                        .logo-img{
                            width: 100px;
                        }
                    }
                }
                .button-otr{
                    display: flex;
                    align-items: center;
                    .member{
                        color: $White-Color;
                        margin: 0 24px 0 0;
                        @include breakpoint($secreen-xxs){
                            display: none;
                        }
                    }
                }
            }
            .row-login{
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 50px 0;
                .col-login-otr{
                    padding: 0;
                    .col-login-inr{
                        position: relative;
                        margin: 0 70px;
                        padding: 44px 48px 52px 48px;
                        background-color: rgba(16, 16, 16, 0.9);
                        border-radius: 24px;
                        @include breakpoint($secreen-max-lg){
                            margin: 0;
                        }
                        @include breakpoint($secreen-xs){
                            padding: 24px 28px 28px 28px;
                        }
                        &::after{
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            opacity: 75%;
                            border-radius: 24px;
                            top: 0;
                            left: 0;
                            backdrop-filter: blur( 4.0px );
                            -webkit-backdrop-filter: blur( 4.0px );
                        }
                        .content{
                            position: relative;
                            z-index: 2;
                            .head{
                                text-align: center;
                                color: $White-Color;
                            }
                            .login-social{
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin: 24px 0;
                                .line{
                                    flex: 1;
                                    background-color: $Dark-bg-tertiary;
                                    height: 1px;
                                }
                                .desc{
                                    color: $Dark-Gray-Shade-1;
                                    padding: 0 12px;
                                }
                            }
                            .btn-main{
                                display: flex;
                                align-items: center;
                                @include breakpoint($secreen-xxs){
                                    flex-direction: column;
                                }
                                .btn-otr{
                                    width: 50%;
                                    @include breakpoint($secreen-xxs){
                                        width: 100%;
                                    }
                                    &:not(:last-child){
                                        margin: 0 30px 0 0;
                                        @include breakpoint($secreen-xxs){
                                            margin: 0 0 30px 0;
                                        }
                                    }
                                    .btn{
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        border: 1px solid $Primary-Color-Home-1;
                                        border-radius: 16px;
                                        padding: 10px 0;
                                        transition: .3s;
                                        &:hover{
                                            background-color: $Primary-Color-Home-1;
                                            color: $White-Color;
                                            .icon{
                                                transition: .3s;
                                                path{
                                                    transition: .3s;
                                                    stroke: $White-Color;
                                                }
                                            }
                                        }
                                        &:focus{
                                            outline: none;
                                            box-shadow: none !important;
                                        }
                                        .icon{
                                            margin: 0 12px 0 0;
                                            transition: .3s;
                                            path{
                                                transition: .3s;
                                                stroke: $Primary-Color-Home-1;
                                            }
                                        }
                                        .text{
                                            transition: .1s;
                                            color: $White-Color;
                                        }
                                    }
                                }
                            }
                            .form-main{
                                .input-otr{
                                    margin: 0 0 30px 0;
                                    .input{
                                        width: 100%;
                                        background-color: $Dark-bg-quaternary;
                                        color: $Dark-Gray-Shade-2;
                                        &::placeholder{
                                            color: $Dark-Gray-Shade-2;
                                        }
                                    }
                                }
                                .input-otr-2{
                                    margin: 0;
                                }
                                .check-main{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    margin: 24px 0;
                                    @include breakpoint($secreen-xxs){
                                        flex-direction: column;
                                    }
                                    .check{
                                        display: flex;
                                        justify-content: space-between;
                                        @include breakpoint($secreen-xxs){
                                            margin: 0 0 16px 0;
                                        }
                                        label{
                                            display: flex;
                                            align-items: center;
                                            margin: 0;
                                            .check-inner{
                                                width: 24px;
                                                height: 24px;
                                                border-radius: 50%;
                                                flex-shrink: 0;
                                                margin-right: 12px;
                                                background-color: $Dark-bg-quaternary;
                                                position: relative;
                                                &:hover{
                                                    cursor: pointer;
                                                }
                                                .input-check{
                                                    opacity: 0;
                                                    position: absolute;
                                                    &:hover{
                                                        cursor: pointer;
                                                    }
                                                }
                                                .input-check:checked + .fill-current {
                                                    display: block;
                                                    width: 24px;
                                                    height: 24px;
                                                    position: absolute;
                                                    left: 50%;
                                                    top: 50%;
                                                    transform: translate(-50%, -50%);
                                                    border-radius: 50%;
                                                }
                                                .fill-current{
                                                    display: none;
                                                    width: 24px;
                                                    height: 24px;
                                                    pointer-events: none;
                                                    position: relative;
                                                    left: -1px;
                                                    top: -1px;
                                                }
                                            }
                                            .select-none{
                                                color: $Dark-Gray-Shade-1;
                                                line-height: 28px;
                                                .terms{
                                                    display: inline;
                                                }
                                            }
                                        }
                                    }
                                    .forget{
                                        color: $Primary-Color-Home-1;
                                    }
                                }
                                .action-otr{
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    .button{
                                        width: 100%;
                                        background-color: $Primary-Color-Home-1;
                                        color: $White-Color;
                                        border: none;
                                        padding: 10px 0;
                                        border-radius: 16px;
                                        @include site-transition;
                                        &:hover{
                                            background-color: $Secondary-Color-Home-1;
                                            color: $Black-Color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .footer-login{
                .copy-inr{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column;
                    }
                    .language-selector{
                        @include breakpoint($secreen-xxs){
                            margin: 0 0 16px 0;
                        }
                        .language-ul{
                            .language-li{
                                position: relative;
                                .language-a{
                                    padding: 9px 24px;
                                    border: 1px solid $White-Color;
                                    border-radius: 16px;
                                    display: flex;
                                    align-items: center;
                                    &:hover{
                                        cursor: pointer;
                                    }
                                    .flag-img{
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 100%;
                                    }
                                    .language{
                                        padding: 0 8px;
                                        color: $White-Color;
                                    }
                                    .caret-down{
                                        position: relative;
                                        top: 1px;
                                        path{
                                            stroke: $White-Color;
                                        }
                                    }
                                }
                                .drop-ul{
                                    padding: 20px 24px;
                                    background-color: $White-Color;
                                    box-shadow: $box-shadow-custom;
                                    border-radius: 16px;
                                    position: absolute;
                                    width: 100%;
                                    top: -164px;
                                    display: none;
                                    .drop-li{
                                        &:not(:last-child){
                                            margin-bottom: 8px;
                                        }
                                        .drop-a{
                                            display: inline-flex;
                                            align-items: center;
                                            &:hover{
                                                cursor: pointer;
                                            }
                                            .flag-img{
                                                width: 24px;
                                                height: 24px;
                                                border-radius: 100%;
                                            }
                                            .language{
                                                color: $Gray-Shade-1;
                                                padding-left: 8px;
                                            }
                                        }
                                    }
                                }
                                .block{
                                    display: block;
                                }
                            }
                        }
                    }
                    .privacy-link{
                        display: flex;
                        align-items: center;
                        .link{
                            color: $White-Color;
                            transition: .3s;
                            &:hover{
                                color: $Secondary-Color-Home-1;
                            }
                        }
                        .dot{
                            color: $White-Color;
                            margin: 0 8px 0;
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
          Contact End Here
-------------------------------------------*/




/*------------------------------------------
            Navbar Start Here
-------------------------------------------*/

.connect-wallet-dark{
    margin: 96px 0 128px 0;
    .container{
        .col-head-otr{
            margin: 0 auto;
            .connect-heading{
                color: $White-Color;
            }
        }
        .line{
            width: 100%;
            height: 1px;
            background-color: $Dark-bg-tertiary;
            margin: 36px 0;
            display: flex;
        }
        .col-connect-otr{
            margin: 0 auto;
            .col-connect-inr{
                .desc{
                    color: $Dark-Gray-Shade-1;
                    margin: 0 0 36px 0;
                    .desc-inr{
                        color: $Primary-Color-Home-1;
                    }
                }
                .row-boxes{
                    .col-box-otr{
                        .box-inr{
                            background-color: $Dark-bg-quaternary;
                            border-radius: 24px;
                            padding: 28px 24px 20px 24px;
                            transition: .3s;
                            &:hover{
                                .head{
                                    color: $White-Color;
                                }
                            }
                            .icon-otr{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                width: 60px;
                                height: 60px;
                                border-radius: 12px;
                                background-color: $Dark-bg-quaternary;
                                margin: 0 0 12px 0;
                            }
                            .head{
                                color: $White-Color;
                                transition: .3s;
                            }
                        }
                        .box-1,.box-2,.box-3{
                            margin: 0 0 24px 0;
                        }
                        .box-1,.box-2,.box-3,.box-4,.box-5{
                            @include breakpoint($secreen-xs){
                                margin: 0 0 24px 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

/*------------------------------------------
            Navbar Start Here
-------------------------------------------*/

// Home 4

/*------------------------------------------
        Hero Section Start Here
-------------------------------------------*/

.hero-4-dark{
    position: relative;
    overflow: hidden;
    background-color: $Dark-Black-2-Color;
    margin: 0 0 128px 0;
    @include breakpoint($secreen-max-sm){
        margin: 0 0 80px 0;
    }
    .nav-otr{
        position: relative;
        .nav-inr{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 32px 45px;
            @include breakpoint($secreen-max-xxl){
                padding: 32px 15px;
            }
            @include breakpoint($secreen-max-md){
                padding: 24px 15px;
            }
            .burger-menu{
                display: flex;
                align-items: center;
                .burger-icon-otr{
                    width: 52px;
                    height: 52px;
                    border-radius: 100%;
                    background-color: $Dark-bg-quaternary;
                    position: relative;
                    margin-right: 12px;
                    .burger-icon{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        &:hover{
                            cursor: pointer;
                            path{
                                stroke: $Primary-Color-Home-1;
                            }
                        }
                        path{
                            stroke: $Gray-Shade-2;
                            transition: .3s;
                        }
                    }
                }
                .logo-otr{
                    .logo-img{
                        width: 100px;
                    }
                }
            }
            .input-main{
                position: relative;
                flex: 1;
                margin: 0 30px;
                @include breakpoint($secreen-max-md){
                    margin: 0 0 0 30px;
                }
                @include breakpoint($secreen-max-sm){
                    display: none;
                }
                .input{
                    width: 100%;
                    padding-right: 52px;
                    background-color: $Dark-bg-quaternary;
                    color: $Dark-Gray-Shade-2;
                    &::placeholder{
                        color: $Dark-Gray-Shade-2;
                    }
                }
                .search-icon{
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    path{
                        stroke: $Dark-Gray-Shade-2;
                    }
                }
            }
            .search-circle{
                width: 52px;
                height: 52px;
                background-color: $Dark-bg-quaternary;
                border-radius: 100%;
                position: relative;
                @include breakpoint($secreen-min-sm){
                    display: none;
                }
                .search-icon{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    path{
                        stroke: $Dark-Gray-Shade-2;
                    }
                }
            }
            .modal-input{
                .modal-dialog-input{
                    .modal-content-input{
                        background-color: $Dark-bg-quaternary;
                        border: none;
                        width: 100%;
                        .input-modal{
                            background-color: $Dark-Black-2-Color;
                            border: none;
                            width: 100%;
                        }
                    }
                }
            }
            .action-otr{
                display: flex;
                align-items: center;
                @include breakpoint($secreen-max-md){
                    display: none;
                }
                .btn-create{
                    margin-right: 16px;
                }
                .btn-wallet{
                    color: $White-Color;
                }
            }
        }
    }
    .wrapper{
        position: relative;
        padding: 104px 0 128px 0;
        @include breakpoint($secreen-max-sm){
            padding: 56px 0 80px 0;
        }
        .content-otr{
            margin: 0 auto;
            .content-inr{
                text-align: center;
                padding: 0 45px 72px 45px;
                @include breakpoint($secreen-max-xxl){
                    padding: 0 15px 72px 15px;
                }
                .heading{
                    color: $White-Color;
                    font-size: 80px;
                    line-height: 106px;
                    @include breakpoint($secreen-max-xxl){
                        br{
                            display: none;
                        }
                    }
                    @include breakpoint($secreen-xs){
                        font-size: 40px;
                        line-height: 64px;
                    }
                }
                .desc{
                    color: $Dark-Gray-Shade-1;
                    font-family: $font-family-DMSans-Regular;
                    padding: 16px 0 36px 0;
                    @include breakpoint($secreen-max-xxl){
                        br{
                            display: none;
                        }
                    }
                    @include breakpoint($secreen-xs){
                        font-size: 18px;
                        line-height: 34px;
                    }
                }
                .action-otr{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include breakpoint($secreen-xxs){
                        flex-direction: column;
                    }
                    .btn-create{
                        margin-right: 16px;
                        @include breakpoint($secreen-xxs){
                            text-align: center;
                            width: 100%;
                            margin: 0 0 16px 0;
                        }
                    }
                    .btn-wallet{
                        color: $White-Color;
                        @include breakpoint($secreen-xxs){
                            text-align: center;
                            width: 100%;
                        }
                    }
                }
            }
        }
        .row-custom1,.row-custom2{
            align-items: center;
            position: relative;
            .col-marquee-otr{
                .col-marquee-inr{
                    .marquee1{
                        .marquee-ul{
                            display: flex;
                            align-items: center;
                            position: relative;
                            left: -1000px;
                            .marquee-li{
                                &:not(:last-child){
                                    margin-right: 50px;
                                }
                                -webkit-text-stroke: 2px $White-Color;
                                -webkit-text-fill-color: transparent;
                                font-size: 100px;
                                line-height: 130px;
                                opacity: 15%;
                                font-family: $font-family-DMSans-Bold;
                                font-style: italic;
                                text-transform: uppercase;
                            }
                        }
                    }
                    .marquee2{
                        .marquee-ul{
                            display: flex;
                            align-items: center;
                            position: relative;
                            left: 1500px;
                            .marquee-li{
                                &:not(:last-child){
                                    margin-right: 50px;
                                }
                                color: $White-Color;
                                font-size: 100px;
                                line-height: 130px;
                                opacity: 15%;
                                font-family: $font-family-DMSans-Bold;
                                font-style: italic;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
            .col-img-otr{
                .col-img-inr{
                    position: relative;
                    border-radius: 24px;
                    z-index: 10;
                    .img1{
                        border-radius: 24px;
                    }
                    .profile-otr{
                        border-radius: 24px;
                        background-color: $White-Color;
                        position: absolute;
                        left: 36px;
                        bottom: 36px;
                        @include breakpoint($secreen-xs){
                            left: 24px;
                            bottom: 24px;
                        }
                        .profile-inr{
                            display: flex;
                            align-items: center;
                            padding: 20px;
                            .user-img{
                                border-radius: 16px;
                                .user-img-inr{
                                    border-radius: 16px;
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                            .username-otr{
                                margin-left: 16px;
                                .user-info{
                                    color: $Gray-Shade-2;
                                }
                                .username{
                                    color: $Black-Color;
                                    margin: -4px 0 0 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .row-custom1{
            padding: 0 45px 0 0;
            @include breakpoint($secreen-max-xxl){
                padding: 0 15px 0 0;
            }
            @include breakpoint($secreen-max-md){
                padding: 0 15px;
                margin-bottom: 30px;
            }
            .col-img-otr{
                padding-left: 42px;
                @include breakpoint($secreen-max-xxl){
                    padding-left: 12px;
                }
                @include breakpoint($secreen-max-md){
                    width: 70%;
                    margin: 0 auto;
                }
                @include breakpoint($secreen-xs){
                    width: 100%;
                }
            }
            .col-marquee-otr{
                @include breakpoint($secreen-max-md){
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-left: 0;
                }
                .col-marquee-inr{
                    .marquee1,.marquee2{
                        .marquee-ul{
                            .marquee-li{
                                @include breakpoint($secreen-max-sm){
                                    font-size: 60px;
                                    line-height: 80px;
                                }
                                @include breakpoint($secreen-xs){
                                    font-size: 40px;
                                    line-height: 64px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .row-custom2{
            padding: 0 0 0 45px;
            position: relative;
            @include breakpoint($secreen-max-xxl){
                padding: 0 0 0 15px;
            }
            @include breakpoint($secreen-max-md){
                padding: 0 15px;
            }
            .col-img-otr{
                padding-right: 42px;
                @include breakpoint($secreen-max-xxl){
                    padding-right: 12px;
                }
                @include breakpoint($secreen-max-md){
                    width: 70%;
                    margin: 0 auto;
                }
                @include breakpoint($secreen-xs){
                    width: 100%;
                }
            }
            .col-marquee-otr{
                @include breakpoint($secreen-max-md){
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    padding-left: 0;
                }
                .col-marquee-inr{
                    .marquee1,.marquee2{
                        .marquee-ul{
                            .marquee-li{
                                @include breakpoint($secreen-max-sm){
                                    font-size: 60px;
                                    line-height: 80px;
                                }
                                @include breakpoint($secreen-xs){
                                    font-size: 40px;
                                    line-height: 64px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .bg-img{
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }
}

/*------------------------------------------
        Hero Section End Here
-------------------------------------------*/
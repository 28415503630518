@mixin site-transition {
    transition: all 0.5s ease-in-out;
    transition: 0.5s linear; /* vendorless fallback */
    -o-transition: 0.5s linear; /* opera */
    -ms-transition: 0.5s linear; /* IE 10 */
    -moz-transition: 0.5s linear; /* Firefox */
    -webkit-transition: 0.5s linear; /*safari and chrome */
}

@mixin dot-animation {
    @keyframes dot-animation {
        0%{
            background-color: $Primary-Color-Home-1;
        }
        50%{
            background-color: $Secondary-Color-Home-1;
        }
        100%{
            background-color: $Primary-Color-Home-1;
        }
    }
}

@keyframes fadeIn {
	100%{
        opacity: 1;
		transform: none;
    }
}

